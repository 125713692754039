// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import "@hotwired/turbo-rails";
// require("@rails/activestorage").start()
// require("channels")

import 'jquery'
import 'popper.js'
import 'bootstrap'
import '@hotwired/turbo-rails'
import './bootstrap_custom'
import "../controllers"
import './dashboard_sidebar'
import './common'

import initSelect2 from './select2';

function initApp() {
  initSelect2();
}

document.addEventListener('DOMContentLoaded', initApp);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Rails.start();
